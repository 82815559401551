import axios from "axios";
// import { setWorkerInfo } from "../features/worker/workerSlice";
import { tokenConfig } from "../helpers/setAuthToken";
import {
  setIncorrectPassword,
  setPasswordRequested,
} from "../features/report/reportSlice";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@ionic-native/file-transfer";
import { Capacitor } from "@capacitor/core";
import { HealthAppointments } from "./workerInfo";
import {
  acceptAppointmentState,
  rejectAppointmentState,
} from "../features/appointment/appointmentSlice";
import {
  setPendingSignature,
  setSignatureComplete,
  clearPendingSignature,
} from "../features/notification/notificationSlice";
import { PendingSignature } from "../features/notification/interfaces";
import { setExpiredSession } from "../helpers/expiredSession";

export interface IAppointments {
  appointments: HealthAppointments[];
}

// New action creators for the signature flow
export const initiateAppointmentSignature =
  (props: PendingSignature) => async (dispatch: any) => {
    dispatch(setPendingSignature(props));
  };

export const clearSignature = () => async (dispatch: any) => {
  dispatch(clearPendingSignature());
};

export const acceptAppointment = (props: any) => async (dispatch: any) => {
  const serverUrl = localStorage.getItem("server_url");

  try {
    const { data } = await axios.get<any>(
      `${serverUrl}/api/accept_appointment/?appointment=${props.appointment_type}&appointment-id=${props.id}`,
      tokenConfig(),
    );

    dispatch(clearPendingSignature());
    dispatch(acceptAppointmentState(props.id));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      setExpiredSession();
      // Clear signature state on error
      dispatch(clearPendingSignature());
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      return "An unexpected error occurred";
    }
  }
};

export const rejectAppointment = (props: any) => async (dispatch: any) => {
  const serverUrl = localStorage.getItem("server_url");

  const reject_extra_reason =
    props.reject_extra_reason && props.reject_extra_reason[0]
      ? props.reject_extra_reason[0]
      : "-";

  try {
    const response = await axios.get<any>(
      `${serverUrl}/api/reject_appointment/?appointment=${props.appointment_type}&appointment-id=${props.id}&appointment-reason=${props.reject_reason}&appointment-extrareason=${reject_extra_reason}`,
      tokenConfig(),
    );

    // Clear signature state after successful rejection
    dispatch(clearPendingSignature());
    dispatch(rejectAppointmentState(props.id));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("error message: ", error.message);
      setExpiredSession();
      dispatch(setIncorrectPassword());
      // Clear signature state on error
      dispatch(clearPendingSignature());
      return error.message;
    } else {
      console.log("unexpected error: ", error);
      return "An unexpected error occurred";
    }
  }
};
