import { useState } from 'react';
import { IonButtons, IonButton, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonLabel, IonInput, IonToast } from '@ionic/react';
import { useParams, useHistory } from 'react-router-dom';
import "./ResetPassword.css"
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../actions/loginInfo';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectResetPasswordError, selectResetPasswordSuccess, clearResetPasswordState } from '../features/login/loginSlice';

interface ResetParams {
    uid: string;
    token: string;
}

const ResetPasswordConfirm = () => {        
    const [t] = useTranslation("global");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [notPasswordCoincidence, setNotPasswordCoincidence] = useState(false);
    const { uid, token } = useParams<ResetParams>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const resetSuccess = useAppSelector(selectResetPasswordSuccess);
    const resetError = useAppSelector(selectResetPasswordError);
    const serverUrl = localStorage.getItem("server_url") || "";

    const handleNewPasswordChange = (e: any) => {
        setNewPassword(e.detail.value);
    }

    const handleConfirmPasswordChange = (e: any) => {
        setConfirmPassword(e.detail.value);
    }

    const handleSubmitNewPassword = async (e: any) => {
        e.preventDefault();
        dispatch(clearResetPasswordState());

        if (newPassword !== confirmPassword) {
            setNotPasswordCoincidence(true);
            return;
        }

        try {
            await dispatch(resetPassword({
                uid,
                token,
                newPassword,
                serverUrl
            }));
        } catch (error) {
            console.error('Error resetting password:', error);
        }
    }

    // Redirect to login on success
    if (resetSuccess) {
        setTimeout(() => {
            history.push('/login');
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('Nueva contraseña')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form className='reset-psw' onSubmit={handleSubmitNewPassword}>
                    <ul className="reset-psw__list">
                        <li className="reset-psw__item">
                            <IonLabel className='reset-psw__label'>{t('Nueva contraseña')}:</IonLabel>
                            <IonInput 
                                required 
                                type="password" 
                                onIonChange={handleNewPasswordChange}
                                value={newPassword}
                            ></IonInput>
                        </li>
                        <li className="reset-psw__item">
                            <IonLabel className='reset-psw__label'>{t('Confirmar contraseña')}:</IonLabel>
                            <IonInput 
                                required 
                                type="password" 
                                onIonChange={handleConfirmPasswordChange}
                                value={confirmPassword}
                            ></IonInput>
                        </li>
                    </ul>
                    <IonButtons>
                        <IonButton className='reset-psw__button' type='submit'>
                            {t('Actualizar contraseña')}
                        </IonButton>
                    </IonButtons>
                </form>
            </IonContent>
            <IonToast
                isOpen={notPasswordCoincidence}
                onDidDismiss={() => setNotPasswordCoincidence(false)}
                message={t('Las contraseñas no coinciden')}
                duration={3000}
                color="danger"
            />
            <IonToast
                isOpen={!!resetError}
                onDidDismiss={() => dispatch(clearResetPasswordState())}
                message={resetError || ''}
                duration={3000}
                color="danger"
            />
            <IonToast
                isOpen={resetSuccess}
                message={t('Contraseña actualizada con éxito')}
                duration={2000}
                color="success"
            />
        </IonPage>
    );
};

export default ResetPasswordConfirm;
