import { useState } from 'react';
import { IonButtons, IonButton, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonLabel, IonInput, IonToast } from '@ionic/react';
import "./ResetPassword.css"
import { useTranslation } from 'react-i18next';
import { sendResetPasswordEmail } from '../actions/loginInfo';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectResetEmailSent } from '../features/login/loginSlice';

const ResetPassword = () => {        
    const [t] = useTranslation("global");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const dispatch = useAppDispatch();
    const resetEmailSent = useAppSelector(selectResetEmailSent);
    const serverUrl = localStorage.getItem("server_url") || "";

    const handleEmailChange = (e: any) => {
        setEmail(e.detail.value);
    }

    const handleSubmitEmail = async (e: any) => {
        e.preventDefault();
        setError("");
        
        try {
            await dispatch(sendResetPasswordEmail(email, serverUrl));
        } catch (error) {
            console.error('Error requesting password reset:', error);
            setError(t('Error al enviar el email. Por favor, inténtelo de nuevo.'));
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('Restablecer contraseña')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form className='reset-psw' onSubmit={handleSubmitEmail}>
                    <ul className="reset-psw__list">
                        <li className="reset-psw__item">
                            <IonLabel className='reset-psw__label'>{t('Email')}:</IonLabel>
                            <IonInput 
                                required 
                                type="email" 
                                onIonChange={handleEmailChange}
                                placeholder="ejemplo@correo.com"
                                disabled={resetEmailSent}
                            ></IonInput>
                        </li>
                    </ul>
                    {!resetEmailSent && (
                        <IonButtons>
                            <IonButton className='reset-psw__button' type='submit'>
                                {t('Enviar')}
                            </IonButton>
                        </IonButtons>
                    )}
                    {resetEmailSent && (
                        <div className="reset-psw__message">
                            {t('Si el email existe en el portal, se te enviará un correo con las instrucciones para reiniciar tu contraseña')}
                        </div>
                    )}
                </form>
            </IonContent>
            <IonToast
                isOpen={!!error}
                onDidDismiss={() => setError("")}
                message={error}
                duration={3000}
                color="danger"
            />
        </IonPage>
    );
};

export default ResetPassword;
