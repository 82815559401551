import "./Report.scss";
import { useState } from "react";
import { OverlayEventDetail } from "@ionic/core/components";
import { useIonModal, IonIcon } from "@ionic/react";
import ModalReportRequest from "./modals/ModalReportRequest";
import {
  calendarClearOutline,
  documentAttachOutline,
  imageOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useIonRouter } from "@ionic/react";

const Questionnaire: any = (props: any) => {
  const {
    id,
    report_type,
    main_reason,
    date,
    aptitudes,
    consultation_files,
    ecg_files,
    image_test_files,
    other_test_files,
    file_type,
    questionnaires,
  } = props.rep;

  const [reportInfo, setReportInfo] = useState<any>({
    report_type: "",
    report_id: "",
    report_name: "",
  });

  const router = useIonRouter();
  // console.log(props.rep)

  // Modal
  const [present, dismiss] = useIonModal(ModalReportRequest, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    reportInfo: reportInfo,
  });

  function openModal({
    file_type,
    id,
    file_name,
  }: {
    file_type: string;
    id: string;
    file_name: string;
  }) {
    setReportInfo({
      report_type: file_type,
      report_id: id,
      report_name: file_name,
    });
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
    });
  }

  function getFileIcon(fileName: string) {
    const type = fileName.split(".").pop();
    let icon = documentAttachOutline;

    const imageExtensions = [
      "png",
      "jpg",
      "jpeg",
      "webp",
      "svg",
      "bitmap",
      "ico",
      "pdf",
    ];

    if (type) {
      if (imageExtensions.includes(type.toLowerCase())) icon = imageOutline;
    }

    return icon;
  }

  const [t, i18n] = useTranslation("global");

  return (
    <div key={id} className="report">
      <div className="report__header">
        <div className="report__title">
          <p>
            {report_type}
            <span className="report__date">
              <IonIcon
                aria-hidden="true"
                className="menu_report__icon"
                ios={calendarClearOutline}
                md={calendarClearOutline}
              />
              {date?.split("T")[0].split("-")[2]}/
              {date?.split("T")[0].split("-")[1]}/
              {date?.split("T")[0].split("-")[0]}
            </span>
          </p>
          {/* <a download title={ t('Solicitar el archivo de') + ' ' + report_type} target="_blank" onClick={()=> openModal(props.rep)} className="report__button">
                        <IonIcon aria-hidden="true" className='report__icon' ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                    </a> */}
        </div>
      </div>
      <div className="report__body">
        <div className="report__props">
          <div className="report__prop">
            <div className="report__prop--title">{t("Motivo")}: </div>
            <div className="report__prop--value">{main_reason}</div>
          </div>

          {questionnaires.map((item: any) => (
            <a
              href="#"
              title={t("Rellenar cuestionnario")}
              className="report__file file"
              key={item.id}
              onClick={(e) => {
                e.preventDefault();
                router.push(item.url, "forward", "push");
              }}
            >
              <span className="file__icon">
                <IonIcon
                  aria-hidden="true"
                  ios={getFileIcon(item.file_name)}
                  md={getFileIcon(item.file_name)}
                ></IonIcon>
              </span>
              <p className="file__info">
                <span className="file__type">
                  {t("Rellenar cuestionnario")}
                </span>
                <span className="file__name">{item.file_name}</span>
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
