import { IonPage, IonContent, IonToast, IonNote, IonItem, IonInput, IonLabel } from "@ionic/react"
import React, { useContext } from "react"
import { Browser } from '@capacitor/browser';
import './Login.css'
import axios from 'axios';

import { getLoginInfo } from '../actions/loginInfo';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectLoginFail, unsetLoginFail } from "../features/login/loginSlice"
import { ServerUrlContext } from '../contexts/ServerUrlContext';
import Toolbar from "../components/Toolbar";
import { useTranslation } from "react-i18next";

const Login: React.FC = ()=>{

    const [t] = useTranslation("global");

    document.title = `${t('Login')} | ${t('Portal de Prevención')}`

    const dispatch = useAppDispatch(); 
    const loginFail = useAppSelector(selectLoginFail);
    const serverUrl = useContext(ServerUrlContext);

    const openResetPasswordSite = async () => {
        await Browser.open({ url: `/password_reset_custom/`});
    };
      
    function login(e: React.SyntheticEvent) {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
        const username = formData.get('username') as string;
        const password = formData.get('password') as string;

        const target = {
            username,
            password,
            serverUrl
        };

        dispatch(getLoginInfo(target))
    }
    
    // const onKeyPressed = ( e : any, n : any)=>{
    //     const input : HTMLInputElement = e.target
    //     const labels: HTMLLabelElement = e.target.closest('ion-input').previousElementSibling

    //     console.log(input)
    //     console.log(labels)
    //     input.value ? labels.classList.add('active') : labels.classList.remove('active')
    // }

    // const companySelector = (e:any) => {
    //     localStorage.setItem('server_url', e.target.value)
    // }

    return (
        <IonPage>
            <Toolbar/>
            <IonContent className="login-content" fullscreen>
                <div className="login">
                    <div className="login__head">
                        <div className="login__title">
                            {t('Portal de Prevención')}
                        </div>
                    </div>
                    <div className="login__wrap">
                        <form action="" className="login__form modal-form" onSubmit={(e) => login(e)}>
                            <ul className="modal-form__ul">
                                <li>
                                    <IonLabel position="stacked">{t('Usuario')}</IonLabel>
                                    <IonInput name="username" id="username" required></IonInput>
                                </li>
                                <li>
                                    <IonLabel position="stacked">{t('Contraseña')}</IonLabel>
                                    <IonInput type="password" name="password" id="password" required></IonInput>
                                </li>
                            </ul>
                            <button className="button-base solid modal-form__submit" title={t('Entrar')} type="submit">{t('Entrar')}</button>
                        </form>
                        <IonNote><button title={t('He olvidado mi contraseña')} onClick={() => openResetPasswordSite()}>{t('He olvidado mi contraseña')}</button></IonNote>
                    </div>
                </div>

            </IonContent>
            <IonToast
                isOpen={loginFail}
                onDidDismiss={() => dispatch(unsetLoginFail())}
                message={t('Usuario o contraseña incorrectos. Vuelva a intentarlo de nuevo, por favor')}
                duration={50000}
                cssClass= 'custom-toast'
                position={ window.innerWidth > 900 ? 'middle' : 'bottom'}
            />
        </IonPage>
    )
}

export default Login