import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  INotifications,
  // PendingSignature
} from "./interfaces";

const initialState: INotifications = {
  departments: [],
  notifications: [],
  pendingSignature: null,
  isSignatureComplete: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    initialNotifications: (state, action) => {
      state.notifications = action.payload.notifications;
      state.departments = action.payload.departments;
    },
    updateNotifications: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },

    // this is to sign appointments that you reject or accept
    setPendingSignature: (state, action) => {
      state.isSignatureComplete = false;
      state.pendingSignature = action.payload;
    },
    clearPendingSignature: (state) => {
      state.isSignatureComplete = false;
      state.pendingSignature = null;
    },
    setSignatureComplete: (state, action) => {
      // state.isSignatureComplete = action.payload; // should i add the payload
      state.isSignatureComplete = true;
    },
  },
});

export const {
  initialNotifications,
  updateNotifications,
  setPendingSignature,
  clearPendingSignature,
  setSignatureComplete,
} = notificationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotifications = (state: RootState) =>
  state.notifications.notifications;
export const selectDepartments = (state: RootState) =>
  state.notifications.departments;
export const selectPendingSignature = (state: RootState) =>
  state.notifications.pendingSignature;
export const selectIsSignatureComplete = (state: RootState) =>
  state.notifications.isSignatureComplete;

export default notificationSlice.reducer;
