import { IonPage, IonContent } from "@ionic/react";

import "./WebSign.scss";
import Toolbar from "../components/Toolbar";
import { t } from "i18next";
import { RouteComponentProps } from "react-router";

import { useEffect, useState, useCallback } from "react";

interface ParamsDetails
  extends RouteComponentProps<{
    type: string;
    id: string;
  }> {}

const QuestionnaireReport: React.FC<ParamsDetails> = ({ match }) => {
  const [reportHtml, setReportHtml] = useState<string>("");

  const fetchReport = useCallback(async () => {
    const urlPath = `${localStorage.getItem("server_url")}/api/questionnaire/${match.params.type}/${match.params.id}/`;

    try {
      const response = await fetch(urlPath, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });

      let html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      // make it so we post to our backend when we decide to save
      const form = doc.getElementById("questionnaire-form");
      if (form) {
        form.setAttribute("method", "POST");
        form.setAttribute("action", urlPath);

        const accessTokenInput = document.createElement("input");
        accessTokenInput.setAttribute("type", "hidden");
        accessTokenInput.setAttribute("name", "access_token");
        accessTokenInput.setAttribute(
          "value",
          localStorage.getItem("access") || "",
        );
        form.appendChild(accessTokenInput);
      }

      // make the save_and_submit appear
      const save_and_submit_button = doc.getElementById(
        "save_and_submit_questionnaire",
      );
      if (save_and_submit_button) {
        save_and_submit_button.style.display = "block";
      }

      html = new XMLSerializer().serializeToString(doc);

      // Use a textarea element to decode HTML entities
      const textArea = document.createElement("textarea");
      textArea.innerHTML = html;
      html = textArea.value;

      setReportHtml(html);
    } catch (error) {
      console.error("Error: ", error);
    }
  }, [match.params.type, match.params.id]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  return (
    <IonPage>
      <Toolbar title={t("Cuestionario")} />
      <IonContent fullscreen>
        <iframe
          title="cuestionario"
          sandbox="allow-forms allow-scripts allow-popups allow-same-origin allow-modals"
          style={{ height: "100%", width: "100%", border: "none" }}
          srcDoc={reportHtml}
        ></iframe>
      </IonContent>
    </IonPage>
  );
};

export default QuestionnaireReport;
