import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, IonRefresher, IonRefresherContent, IonContent, RefresherEventDetail } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import { ServerUrlProvider } from './contexts/ServerUrlProvider';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/base.scss';
import Login from './pages/Login';
import Calendar from './pages/Calendar';
import Vigilancia from './pages/Vigilancia';
import ActivateAccount from './pages/ActivateAccount';
import ResetPassword from './components/ResetPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import React, { useEffect } from "react"
import Notifs from './pages/Notifs';
import MiSalud from './pages/MiSalud';
import { Capacitor } from '@capacitor/core';

// import RouteGuard from './components/RouteGuard';

import { useAppDispatch } from './app/hooks';
import { getWorkerInfo } from './actions/workerInfo';
import {LocalNotifications} from '@capacitor/local-notifications'
import { App as AppFunc, AppState }from '@capacitor/app';
import { open } from './helpers/open';
import { deviceFCMToken, tokenUpdate } from './actions/loginInfo';
// import { Server } from 'http';
import Home from './pages/Home';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import Prevencion from './pages/Prevencion';
import SignReport from './pages/SignReport';
// import WebSign from './pages/WebSign';
import PDFPreview from './pages/PDFPreview';
import QuestionnaireReport from './pages/QuestionnaireReport';

setupIonicReact();

const TOKEN_UPDATE_TIMEOUT = 24 * 60 * 60 * 1000; //in milliseconds
const REFRESH_UPDATE_TIMEOUT = 3 * 60 * 1000; //in milliseconds

const App: React.FC = () => {

  const dispatch = useAppDispatch();

  const initNotifs = ()=>{
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
        throw new Error('User denied permissions!'); 
      }
    });
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      // alert("Devicetoken")
      dispatch(deviceFCMToken(token.value))
    }
    );
    
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.warn('Error on registration: ' + JSON.stringify(error));
    }
    );
    
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.warn('Push received: ' + JSON.stringify(notification));
    }
    );
    
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      console.warn('Push action performed: ' + JSON.stringify(notification));
    }
    );
  }
  

  // token update timeout
  let tokenTimeout:NodeJS.Timeout;
  // let refreshTimeout: NodeJS.Timeout;

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Handle refresh")
    dispatch(getWorkerInfo())
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  const updateRefreshTimeout = () => {
    // refreshTimeout = setTimeout(() => {
    setTimeout(() => {
      if (localStorage.getItem("logged") === "true") {
        dispatch(getWorkerInfo())
        updateRefreshTimeout()
      }
    }, REFRESH_UPDATE_TIMEOUT)
  }

    const tokenUpdateTimeout = () => {
    tokenTimeout = setTimeout(() => {
      if (localStorage.getItem("logged") === "true") {
        dispatch(tokenUpdate())
        tokenUpdateTimeout()
      }
    }, TOKEN_UPDATE_TIMEOUT)
  }
  
  useEffect(() => {
    const isPush = Capacitor.isPluginAvailable('PushNotifications')
    if (isPush) {
      console.log("Plugin available")
      initNotifs()
    }
    dispatch(getWorkerInfo())
    LocalNotifications.addListener('localNotificationActionPerformed', (payload) => {
      const filepath = payload.notification.extra.filepath;
      open(filepath)
    });

    if (localStorage.getItem("logged") === "true") {
      dispatch(tokenUpdate())
      tokenUpdateTimeout()
      updateRefreshTimeout()
    }

    const platform = Capacitor.getPlatform()
    if (platform === "web") {
      document.addEventListener('visibilitychange', function () {
        if (!document.hidden) {
          // Web visible
          if (localStorage.getItem("logged") === "true") {
            dispatch(tokenUpdate())
            tokenUpdateTimeout()
            updateRefreshTimeout()
          }
        } else {
          // Web no visible
          // clearTimeout(tokenTimeout)
          dispatch(tokenUpdate())
          tokenUpdateTimeout()
          updateRefreshTimeout()
        }
      });
    } else {
      AppFunc.addListener('appStateChange', (state: AppState) => {
        console.log('App state changed. Is active?', state.isActive);
        if (state.isActive === true) {
          if (localStorage.getItem("logged") === "true") {
            dispatch(tokenUpdate())
            tokenUpdateTimeout()
            updateRefreshTimeout()
          }
        } else {
          clearTimeout(tokenTimeout)
        }
      });
    }

    return () => {
      LocalNotifications.removeAllListeners()
      clearTimeout(tokenTimeout)
    };
  });

  return (
    <IonApp>
      <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <ServerUrlProvider>
        <IonReactRouter>
          
          <Switch>
            
            <Route path="/login" exact={true} >
              <Login/>
            </Route>
            <Route path="/password_reset_custom" exact={true}>
              <ResetPassword/>
            </Route>
            <Route path="/reset_password/:uid/:token" exact={true}>
              <ResetPasswordConfirm/>
            </Route>
            <Route path="/activate_account/:uuid/:token" exact={true}>
              <ActivateAccount/>
            </Route>

            <IonSplitPane contentId="main">
              <Menu />
              <IonRouterOutlet id="main">
                <PrivateRoute path="/"                          exact={true} component={Home}                ></PrivateRoute>
                <PrivateRoute path="/page/:name"                exact={true} component={Page}                ></PrivateRoute>
                <PrivateRoute path="/vigilancia"                exact={true} component={Vigilancia}          ></PrivateRoute>
                <PrivateRoute path="/notificaciones"            exact={true} component={Notifs}              ></PrivateRoute>
                <PrivateRoute path="/mi-salud"                  exact={true} component={MiSalud}             ></PrivateRoute>
                <PrivateRoute path="/agenda"                    exact={true} component={Calendar}            ></PrivateRoute>
                <PrivateRoute path="/informacion-de-prevencion" exact={true} component={Prevencion}          ></PrivateRoute>
                

                <PrivateRoute path="/firmar/report/:type/:id"   exact={true} component={SignReport}          ></PrivateRoute>
                {/* sign any document path */}
                {/* <PrivateRoute path="/firmar"                    exact={true} component={WebSign}       ></PrivateRoute> */}
                
                <PrivateRoute path="/view/report/:type/:id"     exact={true} component={PDFPreview}          ></PrivateRoute>
                
                <PrivateRoute path="/questionnaire/:type/:id"   exact={true} component={QuestionnaireReport} ></PrivateRoute>
                  
                {/* FALLBACK */}
                <PrivateRoute component={NotFound} ></PrivateRoute>

              </IonRouterOutlet>
            </IonSplitPane>

          </Switch>
        </IonReactRouter>
      </ServerUrlProvider>
      </IonContent>
    </IonApp>
  );
};

export default App;
