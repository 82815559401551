import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ILoginState } from '../../actions/loginInfo';


const initialState: ILoginState = {
  logged: false,
  loginFail: false,
  wrongNewPassword: false,
  passwordValidationMessages: [],
  accountActive: false,
  resetEmailSent: false,
  resetPasswordSuccess: false,
  resetPasswordError: null
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogged: (state) => {
      state.logged = true;
    },
    unsetLogged: (state) => {
      state.logged = false;
    },
    setLoginFail: (state) => {
      state.loginFail = true;
    },
    unsetLoginFail: (state) => {
      state.loginFail = false;
    },
    setWrongPasswordValidation: (state, action) => {
      state.wrongNewPassword = true;
      state.passwordValidationMessages = action.payload
    },
    unsetWrongPasswordValidation: (state) => {
      state.wrongNewPassword = false;
    },
    setAccountActive: (state) => {
      state.accountActive = true;
    },
    unsetAccountActive: (state) => {
      state.accountActive = false;
    },
    setResetEmailSent: (state) => {
      state.resetEmailSent = true;
    },
    unsetResetEmailSent: (state) => {
      state.resetEmailSent = false;
    },
    setResetPasswordSuccess: (state) => {
      state.resetPasswordSuccess = true;
      state.resetPasswordError = null;
    },
    setResetPasswordError: (state, action) => {
      state.resetPasswordSuccess = false;
      state.resetPasswordError = action.payload;
    },
    clearResetPasswordState: (state) => {
      state.resetPasswordSuccess = false;
      state.resetPasswordError = null;
    }
  },
});

export const { 
  setLogged, 
  unsetLogged, 
  setLoginFail, 
  unsetLoginFail, 
  setWrongPasswordValidation, 
  unsetWrongPasswordValidation, 
  setAccountActive, 
  unsetAccountActive,
  setResetEmailSent,
  unsetResetEmailSent,
  setResetPasswordSuccess,
  setResetPasswordError,
  clearResetPasswordState
} = loginSlice.actions;

export const selectLogged = (state: RootState) => (state.login.logged);
export const selectLoginFail = (state: RootState) => (state.login.loginFail);
export const selectWrongNewPassword = (state: RootState) => (state.login.wrongNewPassword);
export const selectPasswordValidationMessages = (state: RootState) => (state.login.passwordValidationMessages);
export const selectAccountActive = (state: RootState) => (state.login.accountActive);
export const selectResetEmailSent = (state: RootState) => (state.login.resetEmailSent);
export const selectResetPasswordSuccess = (state: RootState) => (state.login.resetPasswordSuccess);
export const selectResetPasswordError = (state: RootState) => (state.login.resetPasswordError);

export default loginSlice.reducer;
